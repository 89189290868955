import React from "react";
import {useTheme} from "./theme";
import {useTranslation} from "react-i18next";

function FreeContent(props) {

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div className="wainting_block">
        <p>{t("message_warning")} <br />
        {t("message_warning_2")}</p>

        <div className="aide">
          <a href="#clip">{t("help_waiting")} <img src={theme.arrow.src} alt={theme.arrow.alt}/></a>
        </div>
        <div id="clip">
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/pKuH2uQyemA?autoplay=1&loop=1&modestbranding=1&rel=0&color=red&fs=0&showinfo=0&playlist=pKuH2uQyemA"
              title="Mylène Farmer - Rallumer les étoiles"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export {FreeContent};




