import React from "react";
import {useTheme} from "./theme";
import {useTranslation} from "react-i18next";

function Header(props) {

  const theme = useTheme();
  const { t } = useTranslation();
  

  return (
    <>
      <header>
        <div className="logo-wrapper">
          <img src={theme.logo.src} alt={theme.logo.alt} />
        </div>
        <div className="logo-wrapper-2">
          <img src={theme.logonm.src} alt={theme.logonm.alt}/>
        </div>
        <div className="hourglass">
          <span className="ellipse"></span>
          <img src={theme.hourglass.src} alt={theme.hourglass.alt}/>
        </div>
        <h1 className="title">{t("title_queue")}</h1>
        <p className="desc">{t("desc_queue")}</p>
      </header>
      
      <div className="progression">
        <p>{t("desc_progress")}</p>
      </div>
    </>
  );
}

export { Header };






