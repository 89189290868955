import React from "react";
//import Quit from "../../components/Quit";
import LanguageSelect from "../../components/LanguageSelect";
import {useTranslation} from "react-i18next";

function Footer(props) {

  const { t } = useTranslation();

  return (
    <>
      <div className="footer_block">
        <div className="footer_mentions">
          <div className="langage_switcher">
            <LanguageSelect/>
          </div>
        </div>
      </div>
    </>
  );
}

export { Footer };
