import React from 'react';
import { createTheming } from '@callstack/react-theme-provider';
import './css/style.css';
import logo from './images/Logov2.svg';
import logonm from './images/NM2023.svg';
import hourglass from './images/Sablier.svg';
import arrow from './images/FlecheBas.svg';

const theme = {
  logo: { src: logo, alt: "Logo Mylène Farmer"},
  logonm: { src: logonm, alt: "Logo NEVERMORE 2023"},
  hourglass: {src: hourglass, alt: "Attente icon"},
  arrow: {src: arrow, alt: "Arrow icon"},
  rippleColor: '#FFFFFF',
  themeColor: '#DD0000'
}

const { ThemeProvider, withTheme, useTheme } = createTheming(theme);

function Theme(props) {
  return (<ThemeProvider>{props.children}</ThemeProvider>)
}

export { Theme, ThemeProvider, withTheme, useTheme }
